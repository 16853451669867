import { Navigate, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import logo from "./logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";
import { Button, Container } from "reactstrap";
import AutoForm from "./forms/AutoForm";
import Ship from "./components/Ship";
import AllShips from "./components/AllShips";
import AuthDataPage from "./components/AuthDataPage";
import Test from "./components/Test";
import ImportFromGoogleCalendarModal from "./forms/ImportFromGoogleCalendarModal";
import {
  COMMAND_IMPORT_GOOGLE_CALENDAR,
  COMMAND_CREATE_AUTO,
  COMMAND_UPDATE_AUTO,
  renderHTagWithText,
  renderPTagWithText,
  TAG_H,
  COMMAND_CREATE_SHIP,
} from "./util/Util";
import Scheduler from "./pages/Scheduler";
import DayPicker from "./pages/DayPicker";
import { useState, useEffect, useCallback, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "./actions/auth";

import Home from "./components/Home";
import Error from "./components/Error";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import InformPage from "./components/InformPage";
import { SELECT_SERVICE } from "./actions/types";
import TgAuth from "./components/TgAuth";
import { logout, loginWithUUID } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { JustifyLeft } from "react-bootstrap-icons";
import userService from "./services/user.service";
import UserService from "./services/user.service";
import TgAuthError from "./components/TgAuthError";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Поле должно быть!
      </div>
    );
  }
};
function App() {
  const [showOrganizerBoard, setShowOragnizerBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [show, setShow] = useState(false);
  const { message } = useSelector((state) => state.message);
  const { user: currentUser, errorMessage: errorMessage } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const form = useRef();
  const checkBtn = useRef();
  let location = useLocation();
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  useEffect(
    () => {
      if (currentUser) {
        setShowOragnizerBoard(currentUser.currentRole === "ROLE_ORGANIZER");
        setShowAdminBoard(currentUser.currentRole === "ROLE_ADMIN");
      } else if (errorMessage) {
        navigate("/error", { state: { errorMessage: errorMessage } });
      }
      // else {
      //   navigate("/home");
      //   setShowOragnizerBoard(false);
      //   setShowAdminBoard(false);
      // }
    },
    [
      // currentUser
    ]
  );

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const [currentDate, setCurrentDate] = useState(undefined);
  const [modalShow, setModalShow] = useState(false);
  const [props, setProps] = useState();

  const hideMenu = () => {
    document.getElementById("navbarSupportedContent").className =
      "navbar-collapse top_nav collapse";
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand navbar-brand-custom">
            VELUM
          </Link>
          {currentUser && (
            <Link to={"/profile"} className="nav-link">
              {currentUser.username}
              {currentUser.currentRole === "ROLE_ORGANIZER" ? " 🛳" : ""}
            </Link>
          )}
          <button
            className="navbar-toggler"
            style={{ marginRight: "5px !important" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse top_nav"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto ">
              {/* {showOrganizerBoard && (
                <li className="nav-item">
                  <Link to={"/mod"} className="nav-link">
                    Организатор
                  </Link>
                </li>
              )} */}

              {/* {showAdminBoard && (
                <li className="nav-item">
                  <Link to={"/admin"} className="nav-link">
                    Администратор
                  </Link>
                </li>
              )} */}

              {currentUser && (
                <li className="nav-item">
                  <Link
                    to={"/calendar"}
                    className="nav-link"
                    onClick={hideMenu}
                  >
                    Календарь
                  </Link>
                  {showOrganizerBoard && (
                    <Link
                      to={"/my_ships"}
                      className="nav-link"
                      onClick={hideMenu}
                    >
                      Мои суда
                    </Link>
                  )}
                  {/* <Link
                    to={"/ship"}
                    className="nav-link"
                    onClick={(e) => {
                      dispatch({
                        type: SELECT_SERVICE,
                        payload: { id: undefined, command: "CREATE" },
                      });
                      setShow("SHIP");
                      hideMenu();
                    }}
                  >
                    Добавить судно
                  </Link> */}
                  <Link to={"/profile"} className="nav-link" onClick={hideMenu}>
                    Профиль
                  </Link>
                </li>
              )}
            </ul>
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a href="/" className="nav-link" onClick={logOut}>
                    Выйти
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link" onClick={hideMenu}>
                    Войти с паролем
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Регистрация
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link to={"/tgauth"} className="nav-link" onClick={hideMenu}>
                    Войти через телеграм
                  </Link>
                </li>
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/error" element={<Error />} />
          <Route
            path="/calendar"
            element={
              <Scheduler
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
              />
            }
          />
          <Route path="/my_ships" element={<AllShips />} />
          <Route path="/test" element={<Test />} />
          {/* {show === "SHIP" &&  */}
          <Route path="/ship" element={<Ship />} />
          {/* } */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/tgauth" element={<TgAuth />} />
          <Route path="/tg_auth_error" element={<TgAuthError />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/authdata/:token/:id/:name/:email/:roles/:currentRole/:username"
            element={<AuthDataPage />}
          />
          {/* <Route path="/user" element={<BoardUser />} /> */}
          {/* <Route path="/mod" element={<BoardModerator />} /> */}
          {/* <Route path="/admin" element={<BoardAdmin />} /> */}
        </Routes>
      </div>

      {/* <div style={{ position: "relative" }}>
        <Scheduler currentDate={currentDate} setCurrentDate={setCurrentDate} />
      </div> */}

      {/* <div style={{ position: "relative" }}>
          <CreateBoatForm />
        </div> */}
      {/* <div style={{ position: "relative" }}>
          <AutoPage />
        </div> */}
      {/* <Button
        variant="info"
        onClick={() => setModalShow(COMMAND_IMPORT_GOOGLE_CALENDAR)}
      >
        Импорт из Google календаря
      </Button> */}
      {/* {modalShow === COMMAND_IMPORT_GOOGLE_CALENDAR && (
        <ImportFromGoogleCalendarModal></ImportFromGoogleCalendarModal>
      )} */}
      {/* </div> */}
    </>
  );
}

export default App;
