import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Error = () => {
  const { state } = useLocation();
  const { errorMessage: errorMessage } = state;

  return (
    <div className="container">
      {errorMessage && (
        <header className="jumbotron">
          <h3>{errorMessage}</h3>
        </header>
      )}
    </div>
  );
};

export default Error;
