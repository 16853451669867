import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { Buffer } from "buffer";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import Util from "../util/Util";
import { redirect } from "react-router-dom";
import UserService from "../services/user.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function Ship(props) {
  const ALL_SHIP_SPEED = [
    { value: "KNOTS_5", label: "5 узлов (0 км/ч)" },
    { value: "UP_TO_KNOTS_10", label: "до 10 узлов (19 км/ч)" },
    { value: "UP_TO_KNOTS_25", label: "до 25 узлов (46 км/ч)" },
    { value: "UP_TO_KNOTS_35", label: "до 35 узлов (65 км/ч)" },
  ];
  const ALL_REGISTRATION = [
    { value: "GIMS", label: "ГИМС" },
    { value: "REESTR", label: "Реестр" },
  ];
  const ALL_SAFETIES = [
    { value: "BUOYS", label: "Круги" },
    { value: "JACKETS", label: "Жилеты" },
    { value: "SUITS", label: "Костюмы" },
  ];

  const BOAT_NAME = "Название судна";
  const BOAT_CABIN = "Крытая каюта";
  const BOAT_PASSENGERS = "Вместимость";
  const BOAT_SPEED = "Максимальная скорость";
  const BOAT_TOILET = "Туалет";
  const BOAT_PHOTOS = "Фотографии";
  const BOAT_REGISTRATION = "Регистрация";
  const BOAT_SAFETYS = "Спасательные средства";
  const BOAT_WAVE_HIGH = "Макс. высота волны";

  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [hasCabin, setHaseCabin] = useState(false);
  const [hasToilet, setHaseToilet] = useState(false);
  const [maxCapacity, setMaxCapacity] = useState(undefined);
  const [shipSpeed, setShipSpeed] = useState(undefined);
  const [shipRegistration, setShipRegistration] = useState(undefined);
  const [shipSafety, setShipSafety] = useState([]);
  const [shipWaveHigh, setShipWaveHigh] = useState(undefined);
  const [ship, setShip] = useState(undefined);
  const [shipImageNames, setShipImageNames] = useState();
  const [command, setCommand] = useState();
  const [mainImage, setMainImage] = useState();
  const [deleteFlags, setDeleteFlags] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);

  let navigate = useNavigate();

  useEffect(() => {
    // редактируем судно
    if (message && message.id && message.command) {
      UserService.getShip(message.id).then(
        (response) => {
          const data = response.data;
          setName(data.name);
          setMaxCapacity(data.maxCapacity);
          setShipSpeed(Util.fromEnum(data.shipSpeed, ALL_SHIP_SPEED));
          setShipRegistration(
            Util.fromEnum(data.shipRegistration, ALL_REGISTRATION)
          );
          setShipSafety(Util.fromMultiEnum(data.shipSafety, ALL_SAFETIES));
          setShipWaveHigh(data.shipWaveHigh);
          setHaseCabin(data.hasCabin);
          setHaseToilet(data.hasToilet);
          setShip(data);
          setShipImageNames(data.images);
          setMainImage(data.mainImage);
          setDeleteFlags(
            Array.from({ length: data.images.length }, (i) => (i = false))
          );
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
    }
    // создаём судно
    if (message && message.command) {
      setCommand(message.command);
    } else {
      setCommand("CREATE");
    }
  }, [message]);

  // проверка фотографий и отправка на сервер
  const uploadImagesForId = (id) => {
    if (id && images) {
      if (images.length > 10) {
        setAlertMsg("Максимальное количество загружаемых фотографий 10 штук");
        setImages();

        return;
      }
      if (Array.from(images).filter((img) => img.size > 10485760).length > 0) {
        setAlertMsg("Размер каждой фотографии не должен превышать 10 Мб");
        setImages();

        return;
      }

      if (
        Array.from(images).filter(
          (img) => !img.name.includes(".jpg") && !img.name.includes(".png")
        ).length > 0
      ) {
        setAlertMsg(
          "Загружаемые фотографии должный быть в формате jpg или png"
        );
        setImages();

        return;
      }
      if (images.length > 0) {
        const formData = new FormData();
        for (const image of images) {
          formData.append(
            "images",
            image,
            Util.generateUUID() + "." + image.name.split(".").pop()
          );
        }
        UserService.uploadImages(formData, id)
          .then(
            (response) => {
              response.json();
            },
            (error) => {
              Util.handleError(dispatch, error);
            }
          )
          .then(
            (data) => {
              setShipImageNames(data);
            },
            (error) => {
              Util.handleError(dispatch, error);
            }
          );
      }
    }
  };

  // установка значений
  const setValues = (command, value) => {
    switch (command) {
      case Util.COMMAND_BOAT_CABIN:
        setHaseCabin(value.target.checked);
        break;
      case Util.COMMAND_BOAT_TOILET:
        setHaseToilet(value.target.checked);
        break;
      case Util.COMMAND_SHIP_SPEED:
        setShipSpeed(value);
        break;
      case Util.COMMAND_SHIP_REGISTRATION:
        setShipRegistration(value);
        break;
      case Util.COMMAND_BOAT_SAFETYS:
        const set = new Set(shipSafety.map((el) => el.value));

        if (!set.has(value)) {
          //add
          setShipSafety((prevElements) => {
            const newElements = [...prevElements];
            for (const element of ALL_SAFETIES) {
              if (element.value === value) {
                newElements.push(element);
              }
            }
            return newElements;
          });
        } else {
          //remove
          setShipSafety((prevElements) => {
            const newElements = [...prevElements];
            const fiotered = newElements.filter((el) => el.value !== value);
            return fiotered;
          });
        }
        break;
    }
  };

  // создание судна, отправка на сервер
  const create = () => {
    const createdShip = {
      name: name,
      maxCapacity: maxCapacity,
      shipSpeed: shipSpeed ? Util.toEnum(shipSpeed, ALL_SHIP_SPEED) : undefined,
      shipRegistration: shipRegistration
        ? Util.toEnum(shipRegistration, ALL_REGISTRATION)
        : undefined,
      shipSafety: shipSafety
        ? Util.toMultiEnum(shipSafety, ALL_SAFETIES)
        : undefined,
      hasCabin: hasCabin,
      hasToilet: hasToilet,
      shipWaveHigh: Util.convertToDouble(shipWaveHigh),
    };
    UserService.createShip(createdShip)
      .then(
        (response) => {
          uploadImagesForId(response.data.id);
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      )
      .then(
        () => {
          if (alertMsg === undefined) {
            navigate("/my_ships");
          }
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
  };

  // редактирование судна, отправка на сервер
  const update = () => {
    const updatedShip = {
      id: message.id,
      name: name,
      maxCapacity: maxCapacity,
      shipSpeed: shipSpeed ? Util.toEnum(shipSpeed, ALL_SHIP_SPEED) : undefined,
      shipRegistration: shipRegistration
        ? Util.toEnum(shipRegistration, ALL_REGISTRATION)
        : undefined,
      shipSafety: shipSafety
        ? Util.toMultiEnum(shipSafety, ALL_SAFETIES)
        : undefined,
      hasCabin: hasCabin,
      hasToilet: hasToilet,
      shipWaveHigh: Util.convertToDouble(shipWaveHigh),
      mainImage: mainImage,
      deleteFlags: deleteFlags,
    };
    UserService.updateShip(updatedShip)
      .then(
        (response) => {
          uploadImagesForId(response.data.id);
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      )
      .then(
        () => {
          if (alertMsg === undefined) {
            navigate("/my_ships");
          }
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
  };

  // удаление судна, отправка на сервер
  const deleteShip = () => {
    UserService.deleteShip(ship.id)
      .then(
        (response) => {
          uploadImagesForId(response.data.id);
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      )
      .then(
        () => {
          if (alertMsg === undefined) {
            navigate("/my_ships");
          }
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
  };

  return (
    <>
      {/* ПРЕДУПРЕЖДЕНИЕ */}
      <Modal show={alertMsg} onHide={() => setAlertMsg(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Ошибка загрузки фотографий</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{alertMsg}</p>
          <p className="mb-0">Попробуйте исправить ошибку и загрузить снова</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAlertMsg(undefined)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ЗАГОЛОВОК */}
      {command &&
        Util.renderHTagWithText(
          Util.TAG_H.H5,
          command === "CREATE"
            ? "ДОБАВИТЬ СУДНО"
            : command === "EDIT"
            ? "РЕДАКТИРОВАТЬ СУДНО"
            : "ПРОСМОТР СУДНА",
          {
            display: "flex",
            justifyContent: "center",
          }
        )}

      {/* ФОТО КАРУСЕЛЬ */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {command &&
          shipImageNames &&
          Util.renderCarousel(
            shipImageNames,
            mainImage,
            setMainImage,
            deleteFlags,
            setDeleteFlags,
            command,
            {
              width: "300px",
              height: "225px",
              marginBottom: "15px",
            }
          )}
      </div>

      {/* НАЗВАНИЕ */}
      {command &&
        Util.renderTextField(
          BOAT_NAME,
          name,
          setName,
          false,
          undefined,
          command
        )}

      {/* КОЛИЧЕСТВО ПАССЖИРОВ */}
      {command &&
        Util.renderTextField(
          BOAT_PASSENGERS,
          maxCapacity,
          setMaxCapacity,
          true,
          {
            min: 1,
            max: 50,
          },
          command
        )}

      {/* СКОРОСТЬ */}
      {command &&
        Util.renderListBox(
          BOAT_SPEED,
          ALL_SHIP_SPEED,
          shipSpeed,
          setValues,
          Util.COMMAND_SHIP_SPEED,
          command
        )}

      {/* РЕГИСТРАЦИЯ */}
      {command &&
        Util.renderListBox(
          BOAT_REGISTRATION,
          ALL_REGISTRATION,
          shipRegistration,
          setValues,
          Util.COMMAND_SHIP_REGISTRATION,
          command
        )}

      {/* СПАСТЕЛЬНЫЕ СРЕДСТВА */}
      {command &&
        Util.renderCheckListBox(
          BOAT_SAFETYS,
          ALL_SAFETIES,
          shipSafety,
          setValues,
          Util.COMMAND_BOAT_SAFETYS,
          command
        )}

      {/* ВЫСОТА ВОЛНЫ */}
      {command &&
        Util.renderTextField(
          BOAT_WAVE_HIGH,
          shipWaveHigh,
          setShipWaveHigh,
          false,
          undefined,
          command
        )}

      {/* КАЮТА */}
      {command &&
        Util.renderCheckBoxField2(
          BOAT_CABIN,
          hasCabin,
          setValues,
          command,
          Util.COMMAND_BOAT_CABIN
        )}

      {/* ТУАЛЕТ */}
      {command &&
        Util.renderCheckBoxField2(
          BOAT_TOILET,
          hasToilet,
          setValues,
          command,
          Util.COMMAND_BOAT_TOILET
        )}

      {/* ЗАГРУЗКА ФОТОГРАФИЙ */}
      {command !== "VIEW" &&
        Util.renderUploadFilesField(
          BOAT_PHOTOS,
          setImages,
          true,
          {
            marginLeft: "15px",
            marginTop: "6px",
          },
          !maxCapacity,
          command
        )}

      {/* КНОПКИ */}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          variant="secondary"
          onClick={(e) => navigate(-1)}
          style={{ marginLeft: "5px" }}
        >
          Закрыть
        </Button>
        {command === "CREATE" && (
          <Button
            style={{ marginLeft: "5px" }}
            variant="primary"
            onClick={create}
            disabled={!(name && maxCapacity && shipSpeed)}
          >
            Добавить
          </Button>
        )}
        {command === "EDIT" && (
          <Button
            variant="success"
            onClick={update}
            style={{ marginLeft: "5px" }}
          >
            Сохранить
          </Button>
        )}
        {command === "EDIT" && (
          <Button
            variant="danger"
            onClick={deleteShip}
            style={{ marginLeft: "5px" }}
          >
            Удалить
          </Button>
        )}
      </div>
      <br></br>
    </>
  );
}

export default Ship;
