export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ERROR_401 = "ERROR_401";
export const ERROR_403 = "ERROR_403";
export const ERROR_404 = "ERROR_404";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SELECT_SERVICE = "SELECT_SERVICE";
export const SET_COMMAND_FOR_SELECTED_SERVICE =
  "SET_COMMAND_FOR_SELECTED_SERVICE";
