import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TG_BOT } from "../config";
import Button from "react-bootstrap/Button";

const TgAuthError = () => {
  const goToTelegramBot = () => {
    window.location.href = TG_BOT;
  };
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          Временная ссылка уже не актуальна. Войдите через телеграм получив
          новую ссылку, для этого отправьте боту любое сообщение{" "}
        </h3>
      </header>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="success" onClick={goToTelegramBot}>
          Войти с помощью телеграм
        </Button>
      </div>
    </div>
  );
};

export default TgAuthError;
