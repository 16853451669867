import Modal from "react-bootstrap/Modal";
import { Navigate, useNavigate } from "react-router-dom";
import { Eye, PencilSquare, SquareFill, XCircle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Util from "../util/Util";
import UserService from "../services/user.service";
import { SELECT_SERVICE } from "../actions/types";
import { logout, clearLocalStorage, handle401 } from "../actions/auth";

function AllShips(props) {
  const [ships, setShips] = useState();
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [shipToDelete, setShipToDelete] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (id, name) => {
    setShipToDelete({ ...shipToDelete, id: id, name: name });
    setShow(true);
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      UserService.getShips().then(
        (response) => {
          setShips(response.data);
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
    }
  }, [isLoading]);

  const addShip = () => {
    dispatch({
      type: SELECT_SERVICE,
      payload: { id: undefined, command: "CREATE" },
    });
    navigate("/ship");
  };

  const viewShip = (id) => {
    dispatch({
      type: SELECT_SERVICE,
      payload: { id: id, command: "VIEW" },
    });
    navigate("/ship");
  };

  const editShip = (id) => {
    dispatch({
      type: SELECT_SERVICE,
      payload: { id: id, command: "EDIT" },
    });
    navigate("/ship");
  };

  const deleteShip = () => {
    UserService.deleteShip(shipToDelete.id).then(
      (response) => {
        handleClose();
        setShipToDelete();
        setIsLoading(true);
      },
      (error) => {
        Util.handleError(dispatch, error);
      }
    );
  };

  return (
    <div>
      {Util.renderHTagWithText(Util.TAG_H.H5, "МОИ СУДА", {
        display: "flex",
        justifyContent: "center",
      })}
      {ships && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Название</th>
              <th scope="col">Цвет</th>
              <th scope="col">Управление</th>
            </tr>
          </thead>
          <tbody>
            {ships
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ship, index) => (
                <tr key={ship.id}>
                  <th scope="row">{index}</th>
                  <td style={{ width: "50%" }}>{ship.name}</td>
                  <td style={{ width: "50%" }}>
                    <div>
                      <SquareFill color={ship.color} size={14} />
                    </div>
                  </td>
                  <td>
                    <Eye
                      color="#007bff"
                      size={20}
                      onClick={(e) => viewShip(ship.id)}
                      style={{ cursor: "pointer" }}
                    />
                    <PencilSquare
                      color="#28a745"
                      size={20}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(e) => editShip(ship.id)}
                    />
                    <XCircle
                      color="#dc3545"
                      size={20}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(e) => handleShow(ship.id, ship.name)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          style={{ marginLeft: "5px" }}
          variant="primary"
          onClick={(e) => addShip()}
        >
          Добавить судно
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>УДАЛИТЬ СУДНО</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы действительно хотите удалить судно '
          {shipToDelete && shipToDelete.name}'
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteShip}>
            Удалить
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllShips;
