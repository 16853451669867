import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Calendar,
  CalendarDate,
  Eye,
  Funnel,
  JustifyLeft,
  ListTask,
  PencilSquare,
  PlusCircleFill,
  PlusSquareFill,
  Square,
  SquareFill,
  ViewList,
  XCircle,
} from "react-bootstrap-icons";
import UserService from "../services/user.service";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import DayPicker from "./DayPicker";
import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import EventEditDialog from "./EventEditDialog";
import { EVENT_LOCATION, PROMPTS } from "../util/TempConstant";
import {
  UPDATE_EVENT_SERVICE_ID,
  UPDATE_EVENT_END,
  UPDATE_EVENT_START,
  UPDATE_EVENT_TITLE,
  UPDATE_EVENT_PROMPT,
  UPDATE_EVENT_LOCATION,
  UPDATE_EVENT_DESCRIPTION,
  UPDATE_EVENT_LOADING,
  UPDATE_EVENT_CUSTOMER_NOTES,
  UPDATE_EVENT_ON_SERVER,
  SCHEDULER_DAY,
  SCHEDULER_MONTH,
  SCHEDULER_NEXT,
  SCHEDULER_PREV,
  SCHEDULER_TODAY,
  SCHEDULER_WEEK,
  VIEW_TYPE_DAY,
  VIEW_TYPE_MONTH,
  VIEW_TYPE_WEEK,
  VIEW_TYPE_GRID,
  getFirstMonthDay,
  getRuMonthName,
} from "./event-utils";
import Util from "../util/Util";
import { Label } from "reactstrap";
import { authId, authHeader } from "../services/auth-header";
import { useDispatch, useSelector } from "react-redux";
// import { formatDate } from "react-datepicker/dist/date_utils";

const Scheduler = (props) => {
  const calendarRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [currentSelectedEvent, setCurrentSelectedEvent] = useState();
  const [unSavedEvent, setUnSavedEvent] = useState(false);
  const [api, setApi] = useState();
  const [currentDate, setCurrentDate] = useState(undefined);
  const [picker, setPicker] = useState();
  const [pickerText, setPickerText] = useState();
  const [viewType, setViewType] = useState(SCHEDULER_DAY);

  const [currentViewRange, setCurrentViewRange] = useState();
  const [startDate, setStartDate] = useState();
  const userId = authId();
  const [calenfarHeight, setCalendarHeight] = useState();
  const [ships, setShips] = useState();
  const [shipEvents, setShipEvents] = useState();
  const [allEvents, setAllEvents] = useState();
  const [mode, setMode] = useState();
  const [shipdto, setShipdto] = useState();
  const [isRefreshEvents, setIsRefreshEvents] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRefreshEvents) {
      setIsRefreshEvents(false);
      setApi(calendarRef.current.getApi());
      let calendarAPI = calendarRef.current.getApi();

      setCalendarHeight(calendarRef.current.clientHeight);
      UserService.getEvents(
        viewType === SCHEDULER_DAY ? 0 : viewType === SCHEDULER_MONTH ? 2 : 1,
        calendarAPI.getDate().toISOString()
      )
        .then(
          (response) => {
            const data = response.data;
            setAllEvents(data);
            refreshEvents(data);
          },
          (error) => {
            Util.handleError(dispatch, error);
          }
        )
        .then(() => {
          UserService.getShips().then(
            (response) => {
              setShips(response.data);
              setShipEvents(response.data.map((ship) => ship.id));
            },
            (error) => {
              Util.handleError(dispatch, error);
            }
          );
        });
    }
  }, [isRefreshEvents]);

  const handleDateSelect = (selectInfo) => {
    if (api) {
      api.unselect();
      setUnSavedEvent(true);
      const event = {
        id: createEventId(),
        title: "",
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        extendedProps: {
          description: "",
          prompt: undefined,
          location: undefined,
        },
      };
      setMode(false);
      api.addEvent(event);
      setCurrentSelectedEvent(event);
      setModalShow(true);
    }
  };

  const selectEvent = (info) => {
    setMode("EDIT");
    setCurrentSelectedEvent(info.event);
    setModalShow(true);
  };

  const deleteEvent = (id) => {
    if (api) {
      const event = api.getEventById(id);
      if (event) {
        event.remove();
        UserService.deleteEvent(id).then(
          () => {
            console.log();
          },
          (error) => {
            Util.handleError(dispatch, error);
          }
        );
      }
      setModalShow(false);
    }
  };

  // сохраняем событие
  const saveEvent = (data, isNewEvent) => {
    if (api) {
      const event = api.getEventById(typeof data === "object" ? data.id : data);
      setModalShow(false);
      const newEvent = {
        id: mode && typeof data === "object" ? data.id : "",
        title: event.title,
        userId: authId(),
        color: typeof data === "object" ? data.color : undefined,
        capacity: typeof data === "object" ? data.capacity : undefined,
        loading: typeof data === "object" ? data.loading : undefined,
        eventType: typeof data === "object" ? data.eventType : undefined,
        serviceId: typeof data === "object" ? data.serviceId : undefined,
        description: event.extendedProps.description,
        location: event.extendedProps.location
          ? Util.toEnum(event.extendedProps.location, EVENT_LOCATION)
          : undefined,
        start: event.start,
        stop: event.end,
        status: "BUSY",
        prompt: event.extendedProps.prompt
          ? Util.toEnum(event.extendedProps.prompt, PROMPTS)
          : undefined,
        loading: event.extendedProps.loading,
        customerNotes: event.extendedProps.customerNotes,
      };
      if (mode === "EDIT") {
        UserService.updateEvent(newEvent).then(
          () => {
            setIsRefreshEvents(true);
          },
          (error) => {
            Util.handleError(dispatch, error);
          }
        );
      }
      if (mode === "CREATE") {
        UserService.createEvent(newEvent).then(
          () => {
            setIsRefreshEvents(true);
          },
          (error) => {
            Util.handleError(dispatch, error);
          }
        );
      }
    }
  };

  const createEvent = () => {
    const start = new Date();
    const end = new Date();
    end.setHours(start.getHours() + 24);

    UserService.getShipsDTO().then(
      (response) => {
        setShipdto(response.data);

        UserService.getNextEventId().then(
          (response) => {
            const id = response.data;
            const event = {
              id: id,
              title: "",
              start: start,
              end: end,
              extendedProps: {
                description: "",
                prompt: undefined,
                location: undefined,
              },
            };
            if (api) {
              api.addEvent(event);
              setCurrentSelectedEvent(event);
              setModalShow(true);
            }
          },
          (error) => {
            Util.handleError(dispatch, error);
          }
        );
      },
      (error) => {
        Util.handleError(dispatch, error);
      }
    );
  };

  // обновление события
  const updateEvent = (id, command, value) => {
    if (api) {
      const event = api.getEventById(id);
      switch (command) {
        case UPDATE_EVENT_START:
          event.setStart(value);
          event.setEnd(value);
          break;
        case UPDATE_EVENT_END:
          event.setEnd(value);
          break;
        case UPDATE_EVENT_TITLE:
          event.setProp("title", value);
          break;
        case UPDATE_EVENT_LOCATION:
          event.mutate({
            extendedProps: { location: value },
          });
          break;
        case UPDATE_EVENT_PROMPT:
          event.mutate({
            extendedProps: { prompt: value },
          });
          break;
        case UPDATE_EVENT_DESCRIPTION:
          event.mutate({
            extendedProps: { description: value },
          });
          break;
        case UPDATE_EVENT_LOADING:
          event.mutate({
            extendedProps: { loading: value },
          });
          break;
        case UPDATE_EVENT_CUSTOMER_NOTES:
          event.mutate({
            extendedProps: { customerNotes: value },
          });
        case UPDATE_EVENT_SERVICE_ID:
          event.mutate({
            extendedProps: { serviceId: value },
          });
          break;
        default:
          console.log();
      }
    }
  };

  // отправка на сервер
  const pushToServer = (data) => {
    setMode(true);
    saveEvent(data.id, false);
  };

  const hide = (id) => {
    if (api) {
      if (unSavedEvent) {
        const event = api.getEventById(id);
        event.remove();
        setUnSavedEvent(false);
      }
      setModalShow(false);
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {/* {eventInfo.timeText}  */}
        {eventInfo.event.title}
      </div>
    );
  };

  const changeView = (command) => {
    switch (command) {
      case SCHEDULER_PREV:
        api.prev();
        break;
      case SCHEDULER_NEXT:
        api.next();
        break;
      case SCHEDULER_TODAY:
        api.changeView(VIEW_TYPE_GRID, new Date());
        break;
      case SCHEDULER_MONTH:
        setViewType(SCHEDULER_MONTH);
        api.changeView(VIEW_TYPE_MONTH);
        break;
      case SCHEDULER_WEEK:
        setViewType(SCHEDULER_WEEK);
        api.changeView(VIEW_TYPE_WEEK);
        break;
      case SCHEDULER_DAY:
        setViewType(SCHEDULER_DAY);
        api.changeView(VIEW_TYPE_DAY);
        break;
    }
    setIsRefreshEvents(true);
    setDateAndTitle();
  };

  const setDateAndTitle = (date) => {
    if (date) {
      api.changeView(VIEW_TYPE_MONTH, date.activeStartDate);
      setCurrentDate(date.activeStartDate);
    } else {
      setCurrentDate(api.getDate());
    }
    setCurrentViewRange(api.getCurrentData().viewTitle);
  };

  const onChangeDay = (date) => {
    setViewType(SCHEDULER_DAY);
    setPicker(false);
    api.changeView(VIEW_TYPE_GRID, date);
    setCurrentViewRange(api.getCurrentData().viewTitle);
    setIsRefreshEvents(true);
  };

  const refreshEvents = (data) => {
    let calendarAPI = calendarRef.current.getApi();
    if (calendarAPI) {
      calendarAPI.batchRendering(() => {
        calendarAPI.getEvents().forEach((event) => event.remove());
        data.forEach((event) => {
          event["end"] = event["stop"];
          //fixme mapping!!! not worked
          event.location = Util.fromEnum(event.location, EVENT_LOCATION);
          event.prompt = Util.fromEnum(event.prompt, PROMPTS);
          calendarAPI.addEvent(event);
        });
      });
      setCurrentViewRange(calendarAPI.getCurrentData().viewTitle);
    }
  };

  const addEvents = (data) => {
    let calendarAPI = calendarRef.current.getApi();
    if (calendarAPI) {
      calendarAPI.batchRendering(() => {
        for (const ev of allEvents) {
          for (let index = 0; index < data.length; index++) {
            const id = data[index];
            if (ev.id === id) {
              ev["end"] = ev["stop"];
              ev.location = Util.fromEnum2(ev.location, EVENT_LOCATION);
              ev.prompt = Util.fromEnum2(ev.prompt, PROMPTS);
              calendarAPI.addEvent(ev);
            }
          }
        }
      });
      setCurrentViewRange(calendarAPI.getCurrentData().viewTitle);
    }
  };

  const removeEvents = (data) => {
    let calendarAPI = calendarRef.current.getApi();
    if (calendarAPI) {
      calendarAPI.batchRendering(() => {
        const evs = [];
        calendarAPI.getEvents().forEach((event) => {
          const id = Number(event.id);
          if (!data.includes(id)) {
            evs.push(event);
          }
          event.remove();
        });
        evs.forEach((ev) => calendarAPI.addEvent(ev));
      });
      setCurrentViewRange(calendarAPI.getCurrentData().viewTitle);
    }
  };

  const setViewingShips = (e) => {
    const id = Number(e.target.id);
    const filteredEventIds = allEvents
      .filter((e) => e.serviceId === id)
      .map((e) => e.id);
    if (!shipEvents.includes(id)) {
      setShipEvents([...shipEvents, id]);
      addEvents(filteredEventIds);
    } else {
      setShipEvents(shipEvents.filter((el) => el !== id));
      removeEvents(filteredEventIds);
    }
  };

  const isShipChecked = (ship) => {
    return shipEvents.filter((sh) => sh == ship.id).length > 0;
  };

  return (
    <>
      {/* КНОПКА СОЗДАНИЯ НОВОГО СОБЫТИЯ */}
      <PlusCircleFill
        color="#007bff"
        size={36}
        onClick={(e) => {
          setMode("CREATE");
          createEvent();
        }}
        style={{
          cursor: "pointer",
          position: "absolute",
          bottom: "25px",
          right: "25px",
          zIndex: "101",
        }}
      />

      {/* МОДАЛЬНОЕ ОКНО ВЫБОРА */}
      <Modal show={picker} onHide={() => setPicker(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>{pickerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {picker === "DAY_PICKER" && (
            <div className="sheduler-day-picker">
              <DayPicker
                startDate={startDate}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                onActiveDateChange={setDateAndTitle}
                onChangeDay={onChangeDay}
              />
            </div>
          )}
          {picker === "VIEW_PICKER" && (
            <>
              <Form>
                {[
                  { label: "День", id: SCHEDULER_DAY },
                  { label: "Неделя", id: SCHEDULER_WEEK },
                  { label: "Месяц", id: SCHEDULER_MONTH },
                ].map((element) => (
                  <div key={element.id} className="mb-3">
                    <Form.Check
                      checked={viewType === element.id}
                      type="radio"
                      id={element.id}
                      label={element.label}
                      onChange={(e) => {
                        setViewType(element.id);
                        changeView(element.id);
                        setPicker(undefined);
                        setPickerText(undefined);
                      }}
                    />
                  </div>
                ))}
              </Form>
            </>
          )}
          {picker === "SHIP_PICKER" && (
            <>
              <Form>
                {ships.map((ship) => (
                  <div style={{ display: "flex" }}>
                    <div key={ship.id} className="mb-3">
                      <Form.Check
                        checked={isShipChecked(ship)}
                        type="radio"
                        id={ship.id}
                        label={ship.name}
                        onClick={(e) => setViewingShips(e)}
                      />
                    </div>
                    <div>
                      <SquareFill
                        color={ship.color}
                        size={14}
                        style={{ marginLeft: "10px", marginTop: "16px" }}
                      />
                    </div>
                  </div>
                ))}
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {picker !== "VIEW_PICKER" && (
            <Button
              variant="secondary"
              onClick={() => {
                setPicker(undefined);
                setPickerText(undefined);
              }}
            >
              Ок
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* СОЗДАНИЕ И РЕДАКТИРОВАНИЕ СОБЫТИЯ */}
      {currentSelectedEvent && modalShow && (
        <EventEditDialog
          show={modalShow}
          hide={hide}
          delete={deleteEvent}
          save={(id, mode) => saveEvent(id, mode)}
          update={(id, command, e) => updateEvent(id, command, e)}
          event={currentSelectedEvent}
          mode={mode}
          shipdto={shipdto}
        />
      )}

      {/* КНОПКИ УПРАВЛЕНИЯ КАЛЕНДАРЁМ */}
      <div>
        <label
          style={{
            width: "70hv",
            marginRight: "5px",
            fontSize: "15px",
          }}
        >
          {currentViewRange}
        </label>
        <Calendar
          color="#007bff"
          size={24}
          onClick={(e) => {
            setPicker("DAY_PICKER");
            setPickerText("Выберите дату");
          }}
          style={{
            cursor: "pointer",
            marginBottom: "5px",
            marginLeft: "10px",
          }}
        />
        <ViewList
          color="#007bff"
          size={24}
          onClick={(e) => {
            setPicker("VIEW_PICKER");
            setPickerText("Выберите вид календаря");
          }}
          style={{
            cursor: "pointer",
            marginBottom: "2px",
            marginLeft: "14px",
          }}
        />
        <Funnel
          color="#007bff"
          size={24}
          onClick={(e) => {
            setPicker("SHIP_PICKER");
            setPickerText("Выберите суда");
          }}
          style={{
            cursor: "pointer",
            marginBottom: "1px",
            marginLeft: "8px",
          }}
        />
      </div>

      {/* КАЛЕНДАРЬ */}
      <div className="demo-app" style={{ width: "90vw" }}>
        <div className="demo-app-main">
          <FullCalendar
            ref={calendarRef}
            initialView={VIEW_TYPE_DAY}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            footerToolbar={false}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={2}
            moreLinkContent={function (args) {
              switch (args.num) {
                case 1:
                  return "+ " + args.num + " запись";
                case 2:
                case 3:
                case 4:
                  return "+ " + args.num + " записи";
                default:
                  return "+ " + args.num + " записей";
              }
            }}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
            }}
            nowIndicator={true}
            dayHeaderFormat={{
              weekday: "short",
              // day: "numeric",
              // month: "long",
            }}
            weekNumberFormat={{
              hour: "numeric",
              minute: "2-digit",
            }}
            // navLinks={true}
            allDaySlot={false}
            weekends={true}
            height={(document.documentElement.scrollHeight / 100) * 80}
            firstDay={1}
            locale={"ru"}
            buttonText={{
              today: "сегодня",
              month: "месяц",
              week: "неделя",
              day: "день",
              list: "список",
            }}
            views={{
              dayGridMonth: {
                timeZone: "Europe/Moscow",
                titleFormat: {
                  // year: "numeric",
                  month: "short",
                  day: "2-digit",
                },
              },
              timeGridWeek: {},
            }}
            initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={selectEvent}
            eventDrop={(info) => pushToServer(info.event)}
            eventResize={(info) => pushToServer(info.event)}
            // eventWillUnmount={(info) => changeView(info)}
            // eventDidMount={(info) => changeView(info)}
            // viewDidMount={viewDidMount}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
              eventAdd={function(){}}
              eventChange={function(){}}
              eventRemove={function(){}}
              */
          />
        </div>
      </div>
    </>
  );
};
export default Scheduler;
