import React from "react";

import Button from "react-bootstrap/Button";
import { TG_BOT } from "../config";

const TgAuth = () => {
  const goToTelegramBot = () => {
    window.location.href = TG_BOT;
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="success" onClick={goToTelegramBot}>
          Войти с помощью телеграм
        </Button>
      </div>
    </>
  );
};

export default TgAuth;
