export const FRONT_APP_PORT = 3000;
// export const BACKEND_APP_PORT = ":8080";
export const BACKEND_APP_PORT = "";

// DEVELOPING
// export const BACK_APP_IP = "http://localhost:8080/api";
// export const IMAGES_PATH = "http://localhost";
// export const TG_BOT = "https://t.me/velumprobot";

// PROD
export const BACK_APP_IP = "https://velumpro.ru:443/api";
export const IMAGES_PATH = "https://velumpro.ru/img";
export const TG_BOT = "https://t.me/velumprodevbot";
