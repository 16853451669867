import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Util from "../util/Util";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_EVENT_SERVICE_ID,
  UPDATE_EVENT_END,
  UPDATE_EVENT_START,
  UPDATE_EVENT_TITLE,
  UPDATE_EVENT_PROMPT,
  UPDATE_EVENT_LOCATION,
  UPDATE_EVENT_DESCRIPTION,
  UPDATE_EVENT_LOADING,
  UPDATE_EVENT_CUSTOMER_NOTES,
} from "./event-utils";
import { prompts, EVENT_LOCATION } from "../util/TempConstant";
import { useState, useEffect } from "react";
import UserService from "../services/user.service";

const EventEditDialog = (props) => {
  const [title, setTitle] = useState(props.event.title);
  const [start, setStart] = useState(props.event.start);
  const [stop, setStop] = useState(props.event.end);
  const [showCutomersTable, setShowCutomersTable] = useState();
  const [description, setDescription] = useState(
    props.event.extendedProps.description
  );
  const [loading, setLoading] = useState(props.event.extendedProps.loading);
  const [capacity, setCapacity] = useState(props.event.extendedProps.capacity);
  const [serviceId, setServiceId] = useState();
  const [customerNotes, setCustomerNotes] = useState(
    props.event.extendedProps.customerNotes
      ? props.event.extendedProps.customerNotes
      : []
  );
  const [addedCutomerNote, setAddedCutomerNote] = useState();
  const [addedCutomerCount, setAddedCutomerCount] = useState();
  const [viewCommand, setViewCommand] = useState();
  const [addCustomerAccumulator, setAddCustomerAccumulator] = useState(0);
  const [serviceName, setServiceName] = useState();
  const [eventType, setEventType] = useState();
  const [color, setColor] = useState();
  const dispatch = useDispatch();
  let isLoading = true;

  useEffect(() => {
    if (isLoading && props.mode === "EDIT") {
      isLoading = false;
      UserService.getShipName(props.event.extendedProps.serviceId).then(
        (response) => {
          setServiceName(response.data);
        },
        (error) => {
          Util.handleError(dispatch, error);
        }
      );
    }
  }, []);

  const mapShipDTO = (dtos) => {
    return dtos.map((dto) => {
      return {
        label: dto.name,
        value: dto.id,
        capacity: dto.capacity,
        color: dto.color,
        eventType: dto.eventType,
      };
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.hide(props.event.id)}
    >
      <Modal.Header closeButton>
        {props.mode && (
          <Modal.Title id="contained-modal-title-vcenter">
            {props.mode === "EDIT"
              ? "Редактировать событие"
              : "Создать событие"}
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* ВЫБОР СУДНА */}
          {props.mode === "CREATE" &&
            Util.renderListBox(
              "Судно",
              mapShipDTO(props.shipdto),
              props.event.extendedProps.serviceId,
              (command, target) => {
                const serviceId = props.shipdto
                  .filter((dto) => dto.name === target)
                  .map((dto) => dto.id)[0];
                props.update(props.event.id, command, serviceId);
                const serviceDTO = props.shipdto.filter(
                  (dto) => dto.name === target
                )[0];
                setCapacity(serviceDTO.capacity);
                setEventType(serviceDTO.eventType);
                setColor(serviceDTO.color);
                setServiceId(serviceDTO.id);
                setLoading(0);
              },
              UPDATE_EVENT_SERVICE_ID
            )}

          {/* НАЗВАНИЕ СУДНА*/}
          {props.mode === "EDIT" &&
            Util.renderTextField(
              "Судно",
              serviceName,
              undefined,
              undefined,
              undefined,
              "VIEW"
            )}

          {/* НАЗВАНИЕ */}
          {Util.renderTextField("Название", title, (e) => {
            props.update(props.event.id, UPDATE_EVENT_TITLE, e);
            setTitle(e);
          })}

          {/* НАЧАЛО */}
          {Util.renderDateField(
            "Начало",
            start,
            props.update,
            props.event.id,
            UPDATE_EVENT_START
          )}

          {/* ОКОНЧАНИЕ */}
          {Util.renderDateField(
            "Окончание",
            stop,
            props.update,
            props.event.id,
            UPDATE_EVENT_END
          )}

          {/* ПАССАЖИРЫ */}
          {Util.renderCustomersLoading(
            capacity,
            loading,
            setLoading,
            customerNotes,
            setCustomerNotes,
            showCutomersTable,
            setShowCutomersTable,
            addedCutomerNote,
            setAddedCutomerNote,
            addedCutomerCount,
            setAddedCutomerCount,
            viewCommand,
            setViewCommand,
            addCustomerAccumulator,
            setAddCustomerAccumulator,
            (e) => {
              props.update(props.event.id, UPDATE_EVENT_LOADING, e);
            },
            (e) => {
              props.update(props.event.id, UPDATE_EVENT_CUSTOMER_NOTES, e);
            }
          )}

          {/* ЛОКАЦИЯ */}
          {Util.renderListBox(
            "Локация",
            EVENT_LOCATION,
            props.event.extendedProps.location,
            (command, target) => {
              props.update(props.event.id, command, target);
            },
            UPDATE_EVENT_LOCATION
          )}

          {/* НАПОМИНАНИЕ */}
          {Util.renderListBox(
            "Напомнить",
            prompts,
            props.event.extendedProps.prompt,
            (command, target) => {
              props.update(props.event.id, command, target);
            },
            UPDATE_EVENT_PROMPT
          )}

          {/* ОПИСАНИЕ */}
          {Util.renderTextField("Описание", description, (e) => {
            props.update(props.event.id, UPDATE_EVENT_DESCRIPTION, e);
            setDescription(e);
          })}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {props.mode === "EDIT" && (
          <Button onClick={() => props.delete(props.event.id)} variant="danger">
            Удалить
          </Button>
        )}
        <Button
          disabled={!serviceName && !title}
          onClick={() =>
            props.save(
              {
                id: props.event.id,
                capacity: capacity,
                loading: loading,
                eventType: eventType,
                serviceId: serviceId,
                color: color,
              },
              props.mode
            )
          }
          variant="success"
        >
          Сохранить
        </Button>
        <Button onClick={() => props.hide(props.event.id)} variant="secondary">
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventEditDialog;
