import React, { useState, useEffect } from "react";
import Util from "../util/Util";
import UserService from "../services/user.service";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        Util.handleError(dispatch, error);
      }
      // (error) => {
      //   const _content =
      //     (error.response && error.response.data) ||
      //     error.message ||
      //     error.toString();

      //   setContent(_content);
      // }
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{content}</h3>
      </header>
    </div>
  );
};

export default Home;
