import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearMessage } from "../actions/message";
import { loginWithUUID } from "../actions/auth";
import { useParams } from "react-router-dom";

const AuthDataPage = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const { token, id, name, email, roles, currentRole, username } = useParams();

  useEffect(() => {
    console.log(email);
    dispatch(clearMessage());
    // const rr = role
    //   .replace("[", "")
    //   .replace("]", "")
    //   .replace(",", "")
    //   .split(" ");
    const user = {
      token: token,
      type: "Bearer",
      id: id,
      username: name,
      email: email,
      roles: roles,
      currentRole: currentRole,
      tgName: username,
      accessToken: token,
      tokenType: "Bearer",
    };
    localStorage.clear();
    localStorage.setItem("user", JSON.stringify(user));

    // "{\"token\":\"eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxIiwic3ViIjoiTGlsbyIsImlhdCI6MTczNzE1NTkzNSwiZXhwIjoxNzM3MjQyMzM1fQ.qj-ZOEt2OT5GYaYASFpk-CWsSoj6krzFyVH3EQJOWEA\",
    // \"type\":\"Bearer\",
    // \"id\":1,
    // \"username\":\"Lilo\",
    // \"email\":\"kuranov.andrey@gmail.com\",
    // \"roles\":[\"ROLE_ORGANIZER\"],
    // \"tgName\":null,
    // \"accessToken\":\"eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxIiwic3ViIjoiTGlsbyIsImlhdCI6MTczNzE1NTkzNSwiZXhwIjoxNzM3MjQyMzM1fQ.qj-ZOEt2OT5GYaYASFpk-CWsSoj6krzFyVH3EQJOWEA\",
    // \"tokenType\":\"Bearer\"}";

    // if (isValidLink(location.pathname)) {
    //   const link = location.pathname.substring(1);
    //   dispatch(clearMessage());
    //   dispatch(loginWithUUID(link)).then(() => {
    //     navigate("/");
    //     window.location.reload();
    //   });
    // }
    // setTimeout(() => {
    navigate("/");
    window.location.reload();
    // }, 500);
  }, []);

  return <></>;
};

export default AuthDataPage;
